<template>
    <nav >
   
      <div class="profile" @click="toggleMenu" style="cursor: pointer;">
       
        <div class="img-box text-center">
            <img src="../../assets/images/profile.svg" width="15px" />
            <p class="headerpara">Profile</p>
        </div>
      </div>
      <div class="menu" :class="{ active: isActive }">
        <ul>
          <li><a href="#"> <img src="../../assets/images/usericon.svg" alt="" width="13px"> &nbsp; {{this.rmData?.Name}}</a></li>
          <li><a href="#"><img src="../../assets/images/phoneicon.svg" alt="" width="13px">&nbsp;{{ this.rmData?.Mobile     }}</a></li>
          <li><a href="#"><img src="../../assets/images/mailicon.svg" alt="" width="13px"> &nbsp;{{ this.rmData?.Email }}</a></li>
          
          <li @click="logOutUser()"><a href="#"><img src="../../assets/images/logout.svg" alt="" width="13px">&nbsp;Sign Out</a></li>
        </ul>
      </div>
    </nav>
  </template>
<script>
export default {
  data() {
    return {
      isActive: false,
      rmData:{},
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    logOutUser(){
      let self = this;
      self.$emit('logOutUser');
    }
  },
  mounted(){
    this.rmData = JSON.parse(sessionStorage.getItem('rm_data'))
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/components/inner-header.scss"





/* menu toggle */




/* profile menu */






</style>