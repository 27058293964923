<template>
        <div
          class="varificationModal seperateClass"
          id="subham"
          @click.self="closeModalEditing()"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header justify-content-center">
                <h5 class="modal-title text-align-center mb-3">
          <!-- Using v-if to conditionally render the title -->
          <span v-if="motorProductType === '4W-Pvt'">Edit Car Details</span>
          <span v-else-if="motorProductType === '2W-Pvt'">Edit Bike Details</span>
        </h5>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-between align-items-center mb-3 " >
                  <div >
                    <p class="text-left" style="text-align: left;">RTO</p>
                    <h6> {{ this.userData?.rtoCode || 'N/A' }} </h6>
                  </div>
                  
                  <div v-if="this.userData?.rtoCode">
                    <button @click="navigateStep(2)" class="editform">
                      <img src="../assets/images/edit.svg" alt="">
                    </button>
                    
                  </div>
                  
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                  <div >
                    <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Make</p>
                    <h6>{{this.userData?.carMake?.makeDescription  || 'N/A'}}</h6>
                  </div>
                  
                  <div v-if="this.userData?.carMake?.makeDescription">
                    <button @click="navigateStep(3)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
                    
                  </div>
                  
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                  <div >
                    <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Model</p>
                    <h6>{{this.userData?.model?.modelDesc  || 'N/A'}}</h6>
                  </div>
                  
                  <div v-if="this.userData?.model?.modelDesc">
                    <button @click="navigateStep(4)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
                   
                  </div>
                  
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                  <div >
                    <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Variant</p>
                    <h6>{{this.userData?.variant?.variantDesc  || 'N/A'}}</h6>
                  </div>
                  
                  <div v-if="this.userData?.variant?.variantDesc">
                    <button @click="navigateStep(5)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
                    
                  </div>
                  
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3 ">
                  <div >
                    <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Fuel Type</p>
                    <h6>{{ this.userData?.fuelType?.fuelType  || 'N/A' }}</h6>
                  </div>
                  
                  <div v-if="this.userData?.fuelType?.fuelType">
                    <button @click="navigateStep(6)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
                  </div>
                  
                </div>
      
        
              </div>
            </div>
          </div>
        </div>
      </template>
      <script>
      import $ from 'jquery';
      export default {
        name: "ModalVehicleEditing",
        components: {},
        data() {
          return {
            // items:[
            //   {
            //     RTO:"RTO",
            //     RTOName:"DL-01"
            //   },
            //   {
            //     RTO:"Car Make",
            //     RTOName:"Hyundai"
            //   },
            //   {
            //     RTO:"Car Model",
            //     RTOName:"Grand i10"
            //   },
            //   {
            //     RTO:"Car Fuel Type",
            //     RTOName:"Petrol"
            //   },
            //   {
            //     RTO:"Car Variant",
            //     RTOName:"Prime Era T CRDI (1120 CC)"
            //   }
            // ]
            userData:{},
            motorProductType:'',
          };
        },
        methods:{
                checkCarOrBike(){
                        return this.motorProductType === '4W-Pvt' ? 'Car' : this.motorProductType === '2W-Pvt' ? 'Bike' : null;
                },
                closeModalEditing(){
                        let self = this;
                        console.log("Clicked Modal body")
                        self.$emit('closeModalEditing')
                },
                navigateStep(index){
                        let self = this;
                        //     $('.modal-backdrop').removeClass('show');
                        //     $('.modal-backdrop').remove();
                        //     document.body.setAttribute('style', '');
                        //     document.body.setAttribute('class', '');
                        const userData = JSON.parse(sessionStorage.getItem('userData'));
                        let trigger = index==2 ? 'rto' : index==3 ? 'carMake' : index==4 ? 'carModel' : index==5 ? 'carVariant' : null
                        switch (trigger) {
                                case 'rto':
                                delete userData.carMake;
                                delete userData.model;
                                delete userData.variant;
                                delete userData.fuelType;
                                delete userData.RegDate
                                delete userData.MfgYear
                                delete userData.OdDate
                                delete userData.TpDate

                                break;
                                case 'carMake':
                                delete userData.model;
                                delete userData.variant;
                                delete userData.fuelType;
                                delete userData.RegDate
                                delete userData.MfgYear
                                delete userData.OdDate
                                delete userData.TpDate
                                break;
                                case 'carModel':
                                delete userData.variant;
                                delete userData.fuelType;
                                delete userData.RegDate
                                delete userData.MfgYear
                                delete userData.OdDate
                                delete userData.TpDate
                                break;
                                case 'carVariant':
                                delete userData.fuelType;
                                delete userData.RegDate
                                delete userData.MfgYear
                                delete userData.OdDate
                                delete userData.TpDate
                                break;
                                default:
                                break;
                        }
                        sessionStorage.setItem('userData', JSON.stringify(userData));


                        this.$router.push({ path: '/form-Wizard' });
                        self.$emit('selectTabFromModal', index)
                        self.$emit('closeModalEditing')
                }
        },
        mounted(){
          this.userData = JSON.parse(sessionStorage.getItem("userData"));
          this.motorProductType=this.$store.state.motorProductType;
        }
      };
      </script>
      <style lang="scss">
      @import "../assets/scss/components/modals.scss";
      .seperateClass{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.1);
        backdrop-filter: blur(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        .modal-dialog{
        padding: 0.4rem 0.8rem;
          background-color: #FFF;
          width: 90%;
          max-width: 280px !important;
          border-radius: 0.8rem;
          animation: flipMe 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transform-origin: top;
          @keyframes flipMe {
                0%{
                        transform: scaleY(0);
                }
                100%{
                        transform: scaleY(1);
                }
          }
          .editform{
                padding: 0.6rem;
                border-radius: 50%;
                transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
                &:hover{
                        background-color: rgba(0, 81, 255, 0.1);
                }
          }
        }
        .modal-content{
                min-width: unset !important;
                width: 100% !important;
        }
      }
      </style>
      