<template>
  <article
    class="FilterAccordian headingcards mt-3"
    :key="this.title"
  >
    <header class="d-flex justify-content-between align-items-center">
      <h6 @click="this.ncbExpand = !this.ncbExpand" class="question-title mb-0">
        {{ this.title}}
      </h6>
      <button @click="this.ncbExpand = !this.ncbExpand" class="btn p-0">
        <img src="../../assets/images/minus.svg" v-show="this.ncbExpand" class="plusminusicon"  />
        <img src="../../assets/images/plus.svg" v-show="!this.ncbExpand" class="plusminusicon"   />
       
      </button>
    </header>
    <transition mode="out-in" name="fadeAndSlide">
      <div v-if="ncbExpand">
        <h4>{{ this.info }}</h4>
        <div>
          <div class="form-check form-switch my-3 position-relative p-0">
            <label class="form-check-label headinglabel" for="flexSwitchCheckChecked" >{{ toggleFlag ? 'Yes' : 'No' }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              v-model="toggleFlag"   
              @click="toggleFunction()"
          
            />
          </div>
          <div
            :style="contentStyle"
            class="content"

          >
            <div :style="infoStyle" class="info">
              <div v-if="!this.toggleFlag" v-for="(item,index) in this.userData.percentOptions"
              :key="index" class="form-check mt-2">
                <input
                  v-if="this.title==='Policy Type'"
                  class="form-check-input"
                  type="checkbox"
                  :id="'checkbox_' + item"
                  :value="item"
                  
                />
                <input
                  v-else-if="this.title === 'NCB'"
                  class="form-check-input"
                  type="radio"
                  :id="'radio_' + item"
                  :value="item"
                  :name=" userData.percentOptions" 
                  v-model="selectedItem"
                
                  
                />
                <label
                  :for="'radio_' + item"
                  class="form-check-label"
                >
                  <div class="d-flex align-items-center gap-2">
                    <h5 class="mb-0">{{ item }}</h5>
                  </div>
                </label>
              </div>
              <small v-else>NCB is not applicable</small>
            </div>
          </div>
          
          <div  class="d-flex gap-4 mt-3 justify-content-end">
            <button @click="cancel()" class="btn backbutton" style="width: fit-content">
              Cancel
            </button>
            <button class="btn ApplyButton" @click="apply()">Apply</button>
          </div>
        </div>
      </div>
  </transition>
    
  </article>
</template>

<script>
import { ref, computed} from "vue";

export default {
  data() {
    return {
      toggleFlag:null,
      previous_year_claim:null,
      ncbExpand:true,
      selectedItem:null,
      userData:{},
    };
  },
  name: "FilterAccordian",
  props: {
    title:  {
      type: String,
      required: true,
    },
    info:  {
      type: String,
      required: true,
    },
  },
  mounted(){
//get User data
this.userData = JSON.parse(sessionStorage.getItem("userData"));
this.selectedItem = this.userData.applicablePercentage;
this.previous_year_claim = this.userData.previous_year_claim;
this.toggleFlag = this.userData.previous_year_claim;
},
methods:{
  //Cancel Selected values
  cancel(){
    console.log("Cancel Called")
    this.toggleFlag=null
    this.selectedItem = null;
this.toggleFlag = this.userData.previous_year_claim;
this.selectedItem = this.userData.applicablePercentage;

  },
  //Apply Ncb Change
  apply()
  {
    this.userData.applicablePercentage = this.selectedItem;
    this.userData.previous_year_claim = this.toggleFlag;
    sessionStorage.setItem("userData",JSON.stringify(this.userData))
    this.$emit('update:selected-item', this.selectedItem); // Emit event to parent
  },

  //toggle changed
  toggleFunction(){
    this.toggleFlag = !this.toggleFlag;
    // this.userData.previous_year_claim =  this.toggleFlag 
    if(this.userData.previous_year_claim)
  {
    this.userData.applicablePercentage = 0;
  }
 
    
  },
//   onRadioChange(event){
// this.userData.applicablePercentage = this.selectedItem;

//   }
},

  setup() {
    const expanded = ref(true);
    const contentStyle = computed(() => {
      return { "max-height": expanded.value ? "200px" : "0px" };
    });

    const infoStyle = computed(() => ({
      display: expanded.value ? "block" : "none",
    }));

    return { expanded, contentStyle, infoStyle };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/components/filters.scss";
@import "../../assets/scss/components/select-card.scss";
.fadeAndSlide-enter-from,
.fadeAndSlide-leave-to{
  opacity: 0;
}
.fadeAndSlide-enter-active,
.fadeAndSlide-leave-active{
  transition: opacity 0.3s ease-in-out;
}
</style>
