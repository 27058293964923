<template>
  <nav
    class="navbar navbar-expand-lg inner-header d-flex justify-content-between px-3 py-2 "
  >
    <a class="navbar-brand brand-logo" href="#">
      <img :src="logo" alt="">
    </a>

    
    <div class="modal" id="tokenWarningModal" tabindex="-1" role="dialog" aria-labelledby="tokenWarningModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Session Expiry Warning</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <a>
                    <span class="p-2 modalspan" > Your session is about expire. Please click Ok to continue</span>
                </a>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarningCancel = true"  >Cancel</button>
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarning = true"  >Ok</button>
            </div>
            </div>
        </div>
        </div>
  </nav>
</template>

<script>
import $ from "jquery";
import { getDistributer } from "@/mixins/common";
export default {
  name: "innerheaderDIY",
  emits: ["clicked-rm-details"],
  components: {
    
  },
  mounted() {
    this.logo=getDistributer()["distributorLogo"];
    let rmId = this.$route.query.journeyId;
    try{
    let arr = rmId.split("/");
    rmId = arr[0];
    }
    catch (err){
      console.log("In split catch")
    }
    if(rmId && !this.$store.state.token){
      this.startSession(rmId);
    }
    if (!this.$store.state.configData && this.$store.state.token) {
        this.getConfigData();
      }
    
  },
  data() {
    return {
      logo:"",
      rmData: "",
    };
  },
  methods: {
    getToken() {
      this.backendApi("getToken")
      .then((response) => {
        //console.log('save quote response: ', response);
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data){
          //console.log('response token: ', response.data)

          let token = response.data.Data.Token;
          let USERID = response.data.Data.UserID;
          let key = this.rmData.Userid;

          this.getSPDetails(token, USERID, key);
        }

        
      })
      .catch((error) => {
        console.log('error msg block get token', error);
      });
    },
    getSPDetails(token, USERID, key) {
      let qData = {
        "data":{
          "UserID": USERID, 
          "Token": token,  
          "Key": key 
        }
      }
      this.backendApi("getSPDetails", qData)
      .then((response) => {
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          if(response.data.Data[0] == undefined)
          {
            let tmpData = {
              "advisorCode": "149306",
              "channelType": "BR",
              "cusBankAccNo": "",
              "bankName": "BBBJ",
              "needRiskProfile": "",
              "csrLimCode": "99999 (Dummy code)",
              "cafosCode": "99999",
              "oppId": "",
              "fscCode": "01325137",
              "spCode": "109337",
              "bankBrnch": "SBIN",
              "source": "0BBJ",
              "lanNo": "",
              "selectedTab": "",
              "subChannel": ""
            };            
            sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          }
          else
          {
            console.log("defined SP");
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.Data[0]));
          }          
        }
      })
      .catch((error) => {
        console.log('error msg block sp', error);
      });
    },
    getConfigData() {

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };

      let reqData = [getDistributer()["distributerID"]];

      this.backendApi("getConfigData", reqData, headers, "get")
        .then((response) => {
          if (response == "error") {
            console.log("get config data error: block");
          } else if (response && response.data) {
            console.log("get config data success: response ", response.data.data.Jsondata[0].jsonDataUI);
            this.$store.commit('setConfigData', response.data.data.Jsondata[0].jsonDataUI)
            console.log("hdjfh", this.$store.state.configData)
          }
        })
        .catch((error) => {
          console.log("error msg block get config data", error);
        });

    },
    startSession(rmId){
      let data = {
        journey_id: rmId
      }
      sessionStorage.setItem("journey_id",JSON.stringify(rmId));
      this.$store.commit("setJourneyId", JSON.stringify(rmId));
      this.backendApi("generateTokenOverride", data)
      .then((resp) => {
        console.log("token response =",resp);
        let token = resp.data.data.token.access_token;
        this.$store.commit('setToken',token)
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        console.log("Failed to start session");
      })
    },
    handleHomepage() {
      const distributer = getDistributer();
      if (distributer["distributerID"] === "KVB") {
        window.location.href = distributer.baseURL;
      } else {
        window.location.href = window.location.origin + "/#/";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/inner-header.scss";
</style>
