import axios from "axios";
import { getRequestData } from "./utils";
import store from "../store";
import {getDistributer} from '../mixins/common';
import {generate_secret_key} from "../components/encyrption/secret_generator"
import {rsa_256_encrypt} from "../components/encyrption/rsa_256"
import {aes_256_encrypt, aes_256_decrypt} from "../components/encyrption/aes_256"
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";

const encyrption_flag= getDistributer()['api_encryption']; 
const instance = axios.create({});
instance.defaults.withCredentials = false;
const encryption_feature_flag= encyrption_flag;
function encryptPayload(api,payload)
{
  let localStorageSecretKey=""
  let request_payload={}
  if(!localStorage.getItem("secret_key"))
  {
  localStorageSecretKey=generate_secret_key();
  localStorage.setItem("secret_key",localStorageSecretKey)
  }
  else
  localStorageSecretKey = localStorage.getItem("secret_key")
  if(api=="generateToken" || api=="generateTokenOverride")
  {
    request_payload= {
        "session_id":rsa_256_encrypt(localStorageSecretKey)
    };
  }
  if (payload!=null){
    request_payload["payload"] = aes_256_encrypt(localStorageSecretKey,payload);
}
return request_payload;
}
function decryptResponsePayload(response_payload)
{
    let localData = localStorage.getItem("secret_key")
    response_payload.data = aes_256_decrypt(localData,response_payload.data.payload)
    return response_payload
}
function encryptParamsURL(param)
{
  let localStorageSecretKey=""
  let request_param=""
  if(!localStorage.getItem("secret_key"))
  localStorageSecretKey=generate_secret_key();
  else
  localStorageSecretKey = localStorage.getItem("secret_key")
  request_param={"payload":aes_256_encrypt(localStorageSecretKey,param)}
  console.log(request_param);
  return encodeURIComponent(aes_256_encrypt(localStorageSecretKey,param));
}
export default function backendApi(api, data, headers = {}, method = "post",param={}, multipleParams = {}) {
  const request = getRequestData(api, data);
  //instance.defaults.timeout =10000;
  const body = request.body;
  let url = request.url;
  const baseURL = request.baseURL;
  instance.defaults.baseURL = baseURL;
  let encrypt_payload=body
  if(encryption_feature_flag)
  {
  encrypt_payload = encryptPayload(api,body)
  }
  if(Object.keys(param).length !== 0){
    let index = 1;
    for (const key in param) {
      url = url.replace("param" + index, param[key]);
      index++;
    }
  }
  if(Object.keys(multipleParams).length !== 0){
    let index = 1;
    url+='?';
    for (const key in multipleParams) {
      if (multipleParams[key] !== "") { // Check if value is not empty
        if (index > 0) { // Add '&' before adding subsequent parameters
          url += '&';
        }
        url += key + '=' + encodeURIComponent(multipleParams[key]);
        index++;
      }}
  }
  else{
    let i = 1;
    for (const key in data) {
      url = url.replace("param" + i, data[key]);
      i++;
    }
  }
  if(encryption_feature_flag && api!="generateToken" && api != "generateTokenOverride")
  {
  let param_url_split= url.split("?")
  let param_url=param_url_split[0]
  if(param_url_split.length>1)
  {
    let queryString=param_url_split[1]
    var pairs = queryString.split('&');
    var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
    param_url = param_url+"?"+"payload="+encryptParamsURL(result)
  }
  url=param_url
}
  let header = headers;
  if (api != "generateToken" && api != "refreshToken" && api != "getRmDetails" && api != "generateTokenOverride") {
    let token = store.state.token;
    const decodeToken = jwt_decode(token);

    if (token && checkTokenValidity(token)) {
      header['Authorization'] = `Bearer ${store.state.token}`;
      if(decodeToken.PAYLOAD.externalReferenceId != ""){
      const customer_identifier = decodeToken.PAYLOAD.externalReferenceId;
      header['customer_identifier'] = customer_identifier;
      }
      if(sessionStorage.getItem('journey_id')){
      const journey_id = sessionStorage.getItem('journey_id');
      header['journey_id'] = journey_id;
      }
    } else {
      if(api != "getRmDetailsDIY"){
        sessionStorage.removeItem('vuex')
        window.location.href = `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`  
      }
  }
}
  return wrap(method, { url, encrypt_payload, header })
    .then(response => {
      if(encryption_feature_flag)
      return decryptResponsePayload(response);
      else
      return response
    })
    .catch(error => {
      console.log(api, "ERROR", error.response);
      console.log(error.response);

      // let msg=['Something went wrong, Please try again after some time ']
      // this.$router.push({ path: '/networkalert', query: {backUrl:'dashboard', msg: msg}});

      let data;
      if (error.response) {
        data = {
          "status": 'error',
          "data": error.response.data
        }
      } else {
        data = {
          "status": 'error'
        }
      }
      return data;
    });
}

const wrap = (method, { url, encrypt_payload, header }) => {
  if (method === "get") {
    // console.log(method)
    return instance.get(url, { headers: header })
  } else if (method === "post") {
    return instance.post(url, encrypt_payload, { headers: header })
    
  }
}
const checkTokenValidity = (token) => {
  let decodedToken = parseJwt(token)
  if (decodedToken && decodedToken['expires'] < Date.now() / 1000) {
    // token expired (session)
    console.log("expired");
    return false;
  } else {
    if (((decodedToken['expires'] - Date.now() / 1000) / 60) <= 1) {
      // token getting expired (have to refresh)
      console.log("refresh token");
      let data = {
        currentToken: token
      }
      backendApi("refreshToken", data)
        .then((resp) => {
          let accessToken = resp.data.data.newToken.access_token;
          store.commit('setToken', accessToken)
          return true;
        })
        .catch((error) => {
          console.log("refresh token failed")
          console.log(JSON.stringify(error));
        })
    }
    return true;
  }
}

function parseJwt(token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}
