<template lang="">
  <section class="register-form">
    <div class="row no-gutters ">
      <div class="col-md-6 m-auto text-center">
        <img :src="bank" alt="" class="bankimge" />
        <p class="bank" v-if="motorProductType==='4W-Pvt'">
          Save <b>upto 30%</b> on 
          your 
          Car insurance
          in a minutes
        </p>
        <p class="bank" v-if="motorProductType==='2W-Pvt'">
          Save <b>upto 20%</b> on 
          your 
          Bike insurance
          in a minutes
        </p>
      </div>
      <div class="col-md-6">
        <div class="card">
          <form>
            <div class="form-group">
              
              <input
                type="text"
                class="form-control"
                id="registration-number"
                v-model.trim="userData.vehicleNumber"
                placeholder="Enter Registration Number"
                maxlength="14"
                @input="validateRegistrationNumber(userData.vehicleNumber)"
              />
              <small id="emailHelp" class="form-text text-muted d-flex gap-1 align-items-center"
                >
                <img src="../../assets/images/info.svg" alt="" > 
                    Eg. MH12UP7358 or 22BH2345HH
            </small>
            <p class="error-text" v-if="registrationNoError">{{registrationNoError }}</p>

            <input
                type="text"
                class="form-control mt-3"
                id="mob-number"
                v-model.trim="userData.mobileNumber"
                placeholder=" Mobile Number"
                @keypress="isNumber($event)"
                maxlength="10"
                @input="getFieldValidation('mobile',$event)"
              
              />
              <p class="error-text" v-if="error.mobile">{{ error.mobile }}</p>
              <input
                type="text"
                class="form-control mt-3"
                id="pin-code"
                v-model.trim="userData.pinCode"
                placeholder="Pin Code"
                pattern="[0-9]{6}"              
                :maxlength="6"
                @input="getFieldValidation('pinCode',$event)"
                @keypress="isNumber($event)"


              />
              <p class="error-text" v-if="error.pinCode">{{ error.pinCode }}</p>
              <button class="btn commonButton mt-3" :class="{'disabledButton': checkformData()}" :disabled="incorrectData" @click=searchVehicle()> Continue </button>
              <p class="subhead mt-4 mb-1" v-if="motorProductType==='4W-Pvt'" >
                For <b>New</b> Car
              </p>
              <p class="subhead mt-4 mb-1" v-if="motorProductType==='2W-Pvt'" >
                For <b>New</b> Bike
              </p>
        <router-link to="/form-Wizard" class="nextpagerout" @click="setquoteCategory()">Click here</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { getLandingImgs, getDistributer } from "@/mixins/common";
import backendApi from "@/apis/axios-backend";
import store  from 'vuex';
export default {
  data() {
    return {
      bank: "",
      rto:"",
      registrationNoError:"",
      error:[],
      incorrectData: true,
      loading:false,
      motorProductType:'',
      userInfo:{},
      reqBody:{},
      userData:{
        mobileNumber:null,
        pinCode:null,
        vehicleNumber:null,
        state:null,
        city:null,
        rto:null,
      }
    };
  },
  async mounted() {
    sessionStorage.setItem("userData",JSON.stringify(this.userData));
    //remving oddata so not open 9th step
    if(sessionStorage.getItem("userData")){
      const userdata=JSON.parse(sessionStorage.getItem("userData"));
      userdata.OdDate="";
      sessionStorage.setItem("userData",JSON.stringify(userdata));
    }
    sessionStorage.removeItem("rtoCode");
    this.bank = getLandingImgs().BANK;
    this.motorProductType=this.$store.state.motorProductType;
    if(sessionStorage.getItem("userData")){
      this.userInfo = JSON.parse(sessionStorage.getItem("userData"));
      this.userData.vehicleNumber = this.userInfo.vehicleNumber;
      // this.userData.mobileNumber = this.userInfo.mobileNumber;
      // this.userData.pinCode = this.userInfo.pinCode;
      sessionStorage.removeItem("userSelection")
    }
    const isPreFilled = getDistributer()['isPreFilled'];
    if (this.$store.state.customerData && isPreFilled) {
      const customerData = this.$store.state.customerData;
      this.userData.pinCode = customerData.communicationAddress.zipCode;
      this.userData.mobileNumber = customerData.personalInformation.mobileNumber;
      this.userData.state = customerData.communicationAddress.state;
      this.userData.city = customerData.communicationAddress.city;
      await this.getPincode(this.userData.pinCode);
      this.checkformData();
    }
  },
  methods: {
    setquoteCategory(){
      this.$store.commit("setquoteCategory","New");
    },
    getRto() {
      if (this.userData.vehicleNumber) {  
        const match = this.userData.vehicleNumber.match(/^([A-Z]{2}-?[0-9]{2})/);
        this.rto = match ? match[1] : null;
        console.log("this.rto",this.rto);
        sessionStorage.setItem("rtoCode",JSON.stringify(this.rto));
      } else {
        this.rto = null; 
      }
  },
    searchVehicle(){
      this.getRto();
      this.$store.commit("setquoteCategory","Roll-Over");
      this.$router.push("/form-wizard");
      this.loading = true;
      console.log(this.userData);
      sessionStorage.setItem("userData",JSON.stringify(this.userData));
    },
   
    validateRegistrationNumber(vehicleNumber){
      this.userData.vehicleNumber = this.userData.vehicleNumber.toUpperCase(); 
      const enteredNumber = vehicleNumber.toUpperCase(); 
      const regEx =/^[A-Z]{2}\d{2}(?:[A-Z]{1,3}|)\d{4}$/;
      const regExbharat =/^\d{2}BH(000[1-9]|00[1-9]\d|0[1-9]\d{2}|[1-8]\d{3}|9990)([A-HJ-NP-Z]{1,2})$/;


      
      if (!regEx.test(enteredNumber) && !regExbharat.test(enteredNumber)) {
        this.registrationNoError = 'Kindly enter a valid registration number';
      } else {
        this.registrationNoError = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      var inputValue = evt.target.value;
      if ((inputValue === "0" && inputValue.length > 0) || (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getFieldValidation(name,event){
      if(name=="mobile")
      {
        const regex = /^[6789]\d{9}$/;
        if(regex.test(this.userData.mobileNumber)){
                 this.error["mobile"]=""
        }
      else{ 
        this.error["mobile"] = "Please enter a valid mobile number";
      }
      }
      else if (name=="pinCode") {
          if(this.userData.pinCode && event.target.value.length==6){
            this.getPincode(this.userData.pinCode);        
          }
        else {
          this.incorrectData = true;  
          this.error["pinCode"] = "Please enter a valid Pincode";
        }
      }
    },
    checkformData(){
      
      if (this.error["pinCode"] == "" && this.error["mobile"] == "" && this.registrationNoError == "" && this.userData.vehicleNumber!=null){ 
        this.incorrectData = false;
      }
      else {
        this.incorrectData = true;
      }
   },
    async getPincode(pincode) {
      this.incorrectData = true;
      let headers = {};
      let reqData = [pincode];

      try {
        const response = await this.backendApi("getPincodeInfo", reqData, headers, "get");

        if (response?.data?.errors?.length > 0) {
          this.incorrectData = true;
          this.error["pinCode"] = "Please enter a valid Pincode";
        } else if (response?.data) {
          this.incorrectData = false;
          this.error["pinCode"] = '';
        }
      } catch (error) {
        this.incorrectData = true;
        console.log('error msg block dp', error);
        const msg = "Service is Unavailable, Please try again later.";
        this.$router.push({ path: '/error-page', query: { backUrl: 'New-Journey', msg: msg } });
      }
    },
   
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/register-form";
</style>
